import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import api from './modules/api'

import Common from './modules/Common';

Vue.use(Vuex)

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  // modules: {},
  modules: {
    api,
    Common
  },
  // plugins: [createPersistedState()],
})
