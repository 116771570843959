const en = {
  general: {
    name: 'CareConnect',
    login: 'login',
    zh: 'T. Chinese',
    en: 'English',
    cn: 'S. Chinese',
    preview: 'Preview',
    upload: 'Upload',
    upload_document_file: 'Upload .pdf or image file',
    select_image: 'Select Image',
    image: 'Image',
    view_record: 'View Record',
    year: 'Year',
    cancel: 'Cancel',
    ok: 'OK',
    add: 'Add',
    confirm: 'Confirm',
    edit: 'Edit',
    delete: 'Delete',
    remove: 'Remove',
    save: 'Save',
    document: 'document',
    upload_document: 'Upload Document',
    title: 'Title',
    content: 'Content',
    view_detail: 'View',
    yes: 'Yes',
    no: 'No',
    date: 'Date',
    time: 'Time',
    date_range: 'Date Range',
    file: 'File',
    loading: 'Loading',
    hour: 'Hour | Hours',
    minute: 'Minute | Minutes',
  },
  navigation: {
    worker: 'Worker',
    customer: 'Customer',
    job_post: 'Job Post',
    news: 'News',
    enquiry: 'Enquiry',
    report: "Report",
    new_bug_report: 'New Bug Report',
    report_user: 'User Report',
    report_accident: 'Accident Report',
    job_report: 'Job Report',
    payment_record: 'Payment Record',
    payroll: 'Payroll',
  },
  worker: {
    registration_date: 'Registration Date',
    worker_id: 'Worker ID',
    email: 'Email',
    mobile_number: 'Mobile Number',
    rating: 'Rating',
    status: 'Status',
    approval_status: 'Approval Status',
    worker_detail: 'Worker Detail',
    worker_account: 'Worker Account',
    location: 'Location',
    working_hour_support: 'Working hours support per week',
    media_to_know: 'Media to know CareConnect',
    worker_profile: 'Worker Profile',
    profile_photo: 'Profile Photo',
    first_name: 'First Name',
    last_name: 'Last Name',
    nickname: 'Nickname',
    bio: 'Bio',
    gender: 'Gender',
    date_of_birth: 'Date of Birth',
    residential_address: 'Residential Address',
    postal_address: 'Postal Address',
    emergency_contacts: 'Emergency Contacts',
    name: 'Name',
    relationship: 'Relationship',
    services: 'Services',
    social_support: 'Social Support & Domestic Assistance',
    personal_care: 'Personal Care',
    nursing_registration: 'Nursing Registration',
    nursing_type: 'Nursing Type',
    registered: 'Registered',
    enrolled_nurse: 'Enrolled nurse',
    registration_number: 'Registration Number',
    expiry_date: 'Expiry Date',
    allied_health: 'Allied-Health Services',
    additional_training: 'Additional Training',
    verifications: 'Verifications',
    covid_19_training: "COVID-19 Training",
    sexual_conviction_record:"Sexual Conviction Record",
    reference_number:'Reference Number',
    how_do_you_know_the_referee: 'How do you know the referee?',
    how_long_have_known_your_referee: 'How long have known your referee?',
    company: 'Company',
    bank_account: 'Bank Account',
    account_name: 'Account Name',
    bank_name: 'Bank Name',
    account_number: 'Account Number',
    rates_and_availability: 'Rates and Availability',
    avail_for_work: 'Available for Work',
    locations: 'Locations',
    preferred_days: 'Preferred Days',
    display_rates: 'Display Rates',
    weekday: 'Weekday',
    weekend: 'Weekend',
    hour_shift: '24-hour shift',
    hourly_rate: 'Hourly rate: $',
    flat_rate: 'Flat rate: $',
    meet_and_greet: 'Meet & greet',
    profile: 'Profile',
    work_history: 'Work History',
    job_title: 'Job Title/Role',
    start_date: 'Start Date',
    end_date: 'End Date',
    current_working_job: 'Currently working in this job',
    education_and_training: 'Education & Training',
    institution: 'Institution',
    degree_course: 'Degree/Course',
    current_working_degree: 'Currently working in this degree/course',
    care_experience: 'Care Experience',
    aged_care: 'Aged Care',
    chronic: 'Chronic Medical Conditions',
    disability: 'Disability',
    mental_health: 'Mental Health',
    badges: 'Badges',
    first_language: 'First Language',
    other_languages: 'Other Languages',
    cultural_background: 'Cultural Background',
    religion: 'Religion',
    interest: 'Interests & hobbies',
    personality: 'Personality',
    personality_at_work: 'Personality at work',
    non_smoker: 'Are you a non-smoker?',
    per_friendly: 'Are you pet-friendly?',
    prefer_to_work_with: 'Who do you prefer to work with',
    my_preferences: 'My Preferences',
    calm: 'Calm and Composed',
    outgoing: 'Outgoing and sociable',
  },
  customer: {
    registration_date: 'Registration Date',
    customer_id: 'Customer ID',
    email: 'Email',
    mobile_number: 'Mobile Number',
    number_of_agreement: 'Number of Agreement',
    status: 'Status',
    customer_detail: 'Customer Detail',
    customer_account: 'Customer Account',
    who_is_seeking_support: 'Who is seeking support?',
    are_you_18_or_over: 'Are you 18 years or over?',
    name_receive_support: 'Name of the person who will be receiving support',
    customer_profile: 'Customer Profile',
    need_assistance_with_medication: 'Do you or a participant need any assistance with medication?',
    type_of_care_need: 'What type of care is needed?',
    participant_detail: 'Participant′s Details',
    same_as_ac: 'Same as account profile',
    emergency_response: 'Emergency Response and Access Information',
    allergies_medical_alert: 'Allergies and medical alerts',
    household_detail: 'Household Detail',
    smoker_living_house: 'Are there smokers living in the house?',
    equipment_available: 'Equipment available',
    pet: 'Pet',
    access_information: 'Access Information',
    dog: 'Dog',
    cat: 'Cat',
    other_pets: 'Other pets',
    safe_working_environment: 'Provide a safe working environment for the services requested',
    social_needs_goals: 'Social Needs and Goals',
    interest_hobbies_love: 'Interests / Hobbies / Loves',
    medical_living_needs_goals: 'Medical, Living Needs and Goals',
    instruction_for_support_worker: 'Instructions for independent support worker',
    instruction: 'Instruction',
    medication: 'Medication',
    dosage: 'Dosage',
    frequency: 'Frequency',
    reason_taken: 'Reason Taken',
    care_plan: 'Care Plan',
    gp_documents: 'GP Documents',
    other_document: 'Other Document',
  },
  job_post: {
    create_date: 'Create Date',
    job_id: 'Job ID',
    customer_id: 'Customer ID',
    customer_first_name: 'Customer First Name',
    customer_last_name: 'Customer Last Name',
    job_title: 'Job Title',
    job_type: 'Job Type',
    status: 'Status',
    job_detail: 'Job Detail',
    view_customer: 'View Customer',
    estimated_hours_per_week: 'Estimated hours per week',
    support_person_need_to_do: 'Support person need to do',
    nursing_service: 'Nursing Service',
    gender_preference: 'Gender Preference',
    important_to_know: 'Important to know',
    worker_preference: 'Worker Preference',
    payment_detail: 'Payment Detail',
    payment_status: 'Payment Status',
    payment_method: 'Payment Method',
    agreement: 'Agreement',
    worker_id: 'Worker ID',
    view_worker: 'View Worker',
    worker_first_name: 'Worker First Name',
    worker_last_name: 'Worker Last Name',
    agreement_status: 'Agreement Status',
    agreed_service_description: 'Agreed Service Description',
    shift_note: 'Shift Note',
    total_amount: 'Total Amount',
    salary_amount: 'Salary Amount',
    participants: 'Participants',
    agreed_rate: 'Agreed Rate',
    age: 'Age',
    working_dates: 'Working Dates',
    start_time: 'Start Time',
    end_time: 'End Time',
    hourly_flat: 'Hourly Rate/ Flat Rate',
    total_hours: 'Total Hours',
    hourly_rate: 'Hourly Rate',
    flat_rate: 'Flat Rate',
    timesheet: 'Timesheet',
    timesheet_detail: 'Timesheet Detail',
    timesheet_status: 'Timesheet Status',
    payroll_paid_date: 'Payroll Paid Date',
    complete_date: 'Complete Date',
    offline: 'Offline',
    credit_card: 'Credit Card',
    add_participants:"Add Participants"
  },
  news: {
    image: 'Image',
    news_date: 'News Date',
    title_zh: 'Title (ZH)',
    title_en: 'Title (EN)',
    add_news: 'Add News',
    news_detail: 'News Detail',
    news_content: 'News Content',
  },
  enquiry: {
    contact_number: 'Contact Number',
  },
  payroll: {
    payroll_detail: 'Payroll Detail',
  },
  instruction: {
    create_success: 'Create Success',
    create_fail: 'Create Fail',
    update_success: 'Update Success',
    update_fail: 'Update Fail',
    remove_success: 'Remove Success',
    remove_fail: 'Remove Fail',
    upload_success: 'Upload Success',
    upload_fail: 'Upload Fail',
    file_not_found: 'File not Found',
    update_not_required: 'Updated is not required',
    remove_item: 'Do you want to remove this',
  },
  error: {
    force_input: 'Please input the',
    force_select: 'Please select the',
    force_upload: 'Please upload a',
    document: 'document',
    image_file: 'image file',
    image_pdf_file: 'image or .pdf file',
    file: 'file',
    content: 'content',
    number: 'number',
    floating: 'floating number',
    only: 'only',
    format: 'format',
    invalid: 'Invalid',
    select_at_least_two: 'Please select at least two items',
    select_at_least_one: 'Please select at least one item',
    item_and_work_experience: 'Please select at least one item and the work experience item',
    item_and_experience: 'Please select at least one item and the 1 or more years of experience item',
    compare_date: 'Start Date cannot exceed End Date',
    fill_all_required_field: 'PLease fill in all the required field',
    does_not_exist: 'does not exist',
  },
  search: {
    search: 'Search',
    worker_name: 'Worker name',
    tel: 'tel',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    approved: 'Approved',
    unapproved: 'Unapproved',
    completed: 'Completed',
    accepted: 'Accepted',
    decline: 'Decline',
    paid: 'Paid',
    filled: 'Filled',
    canceled: 'Canceled',
  },
  job_type: {
    last_minute: 'Last Minute',
    standard: 'Standard',
    private: 'Private',
  },
  gender: {
    male: 'Male',
    female: 'Female',
    not_to_say: 'Prefer Not to Say',
  },
  relationship: {
    partner_spouse: 'Partner/ Spouse',
    immediate_family: 'Immediate Family',
    extended_family: 'Extended Family',
    legal_representative_advocate: 'Legal Representative/ Advocate',
    approved_provider_care_coordinator: 'Approved Provider/ Care Coordinator',
    friend_neighbour: 'Friend/ Neighbour',
  },
  service_social_support_field: {
    activities_outings_community_access: 'Activities, Outings & Community Access',
    home_maintenance: 'Home Maintenance',
    light_housework: 'Light Housework',
    meal_delivery: 'Meal Delivery',
    meal_preparation: 'Meal Preparation',
    personal_assistant: 'Personal Assistant (Admin)',
    provide_transport: 'Provide Transport',
    shopping: 'Shopping',
    sports_exercise: 'Sports & Exercise',
    work_experience: 'Work Experience',
  },
  service_personal_care_field: {
    combined_health_worker_training_course: 'Certificate of Combined Health Worker Training Course',
    residential_care_homes_for_elderly: 'Certificate of Training Course for Health Worker in Residential Care Homes for the Elderly',
    residential_care_homes_for_disabilities: 'Certificate of Training Course for Health Worker in Residential Care Homes for Persons with Disabilities',
    practising_certificates_for_registered: 'Practising certificates for Registered',
    enrolled_nurse: 'Enrolled nurse issued by the Nursing Council',
    working_toward_degree_nursing: 'Working Toward Degree Nursing',
    other_qualification: 'Other relevant qualification',
    experience: '1 or more years of experience',
    assistance_with_eating: 'Assistance with Eating',
    assist_with_medication: 'Assist with Medication',
    care_assessment: 'Care Assessment, Planning, Coordination',
    exercise_assistance: 'Exercise Assistance',
    hoist_transfer: 'Hoist & Transfer',
    light_massage: 'Light Massage',
    manual_transfer_and_mobility: 'Manual Transfer and Mobility',
    palliative_care: 'Palliative Care',
    showering_dressing_grooming: 'Showering, Dressing, Grooming',
    toileting: 'Toileting',
  },
  nursing_registration_field: {
    conduction_health_assessments: 'Conducting health assessments',
    medication_management: 'Medication management',
    chronic_disease_monitoring: 'Chronic disease monitoring',
    pain_management: 'Pain management',
    oxygen_therapy: 'Oxygen therapy',
    post_operative_recovery: 'Post-operative recovery',
    wound_care: 'Wound care',
    catheter_care: 'Catheter care',
    dementia_support: 'Dementia support',
    continence_management: 'Continence management',
    palliative_care: 'Palliative care',
  },
  allied_health_field: {
    occupational_therapy: 'Occupational Therapy',
    physiotherapy: 'Physiotherapy',
    psychological_services: 'Psychological Services',
    speech_therapy: 'Speech Therapy',
    social_work: 'Social Work',
  },
  additional_training_field: {
    first_aid: 'First Aid',
    manual_handing_certificate: 'Manual Handling Certificate',
    valid_driver_licence: 'Valid Driver’s Licence',
  },
  location: {
    central: 'Central and Western',
    east: 'Eastern',
    south: 'Southern',
    wc: 'Wan Chai',
    klc: 'Kowloon City',
    kt: 'Kwun Tong',
    ssp: 'Sham Shui Po',
    wts: 'Wong Tai Sin',
    ysm: 'Yau Tsim Mong',
    island: 'Islands',
    kc: 'Kwai Tsing',
    north: 'North',
    sk: 'Sai Kung',
    st: 'Sha Tin',
    tp: 'Tai Po',
    tw: 'Tsuen Wan',
    tm: 'Tuen Mun',
    yl: 'Yuen Long',
  },
  days: {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
  },
  aged_care_field: {
    dementia: 'Dementia',
    parkinson_disease: 'Parkinson’s Disease',
  },
  chronic_field: {
    arthritis: 'Arthritis',
    asthma: 'Asthma',
    cardiovascular_disease: 'Cardiovascular Disease',
    copd: 'COPD or Respiratory Illness',
    diabetes: 'Diabetes',
  },
  disability_field: {
    acquired_brain_injury: 'Acquired Brain Injury',
    autism: 'Autism',
    cerebral_palsy: 'Cerebral Palsy',
    cystic_fibrosis: 'Cystic Fibrosis',
    down_syndrome: 'Down Syndrome',
    epilepsy: 'Epilepsy',
    hearing_impairment: 'Hearing Impairment',
    intellectual_disabilities: 'Intellectual Disabilities',
    motor_neuron_disease: 'Motor Neuron Disease',
    spina_bifida: 'Spina Bifida',
    spinal_cord_injury: 'Spinal Cord Injury',
    vision_impairement: 'Vision Impairement',
  },
  mental_health_field: {
    anxiety: 'Anxiety',
    bipolar_disorder: 'Bipolar Disorder',
    depression: 'Depression',
    eating_disorders: 'Eating Disorders',
    hoarding: 'Hoarding',
    ocd: 'Obessive-Compulsive Disorder (OCD)',
    ptsd: 'Post-traumatic Stress Disorder (PTSD)',
    schizophrenia: 'Schizophrenia',
    substance_abuse_addiction: 'Substance Abuse & Addiction',
  },
  badges_field: {
    covid: 'COVID-19 vaccine declaration',
    flu: 'Flu vaccine',
    lgbti: 'LGBTI, support for people in the LGBTI community',
    yes_one_dose: 'Yes, at least one dose',
    yes_fully_vaccinated: 'Yes, fully vaccinated',
  },
  first_language_list: {
    cantonese: 'Cantonese',
    english: 'English',
    mandarin: 'Mandarin',
    arabic: 'Arabic',
    french: 'French',
    german: 'German',
    indonesian: 'Indonesian',
    italian: 'Italian',
    japanese: 'Japanese',
    korean: 'Korean',
    spanish: 'Spanish',
    music: 'Music',
    vietnamese: 'Vietnamese',
  },
  religion_list: {
    buddhism: 'Buddhism',
    taoism: 'Taoism',
    confucianism: 'Confucianism',
    catholic: 'Catholic',
    christian: 'Christian',
    muslim: 'Muslim',
    hindu: 'Hindu',
    sikh: 'Sikh',
    other: 'Other religion',
    no_religion: 'No religion',
  },
  interest_list: {
    cooking: 'Cooking',
    pets: 'Pets',
    cultural_festivities: 'Cultural Festivities',
    photography: 'Photography',
    art: 'Art',
    reading: 'Reading',
    indoor_games: 'Indoor Games',
    puzzles: 'Puzzles',
    sports: 'Sports',
    movies: 'Movies',
    travel: 'Travel',
    music: 'Music',
    other: 'Other',
  },
  prefer_to_work_with: {
    non_smoker: 'Non-smoker',
    no_pet: 'No Pets',
    no_preference: 'No Preferences',
  },
  type_of_care_list: {
    disability_support: 'Disability Support',
    aged_care: 'Aged Care',
    mental_health: 'Mental Health',
    post_surgery: 'Post-surgery',
    other: 'Other',
  },
  medical_living_needs_goals: {
    cardiac_vascular: 'Cardiac / Vascular',
    diabetes_cholesterol: 'Diabetes / Cholesterol',
    incontinence: 'Incontinence (bladder or bowel)',
    memory_loss_dementia: 'Memory Loss / Dementia',
    nutrition_hydration: 'Nutrition / Hydration',
    other_barriers_or_concerns: 'Other barriers or concerns',
    physical_mobility: 'Physical / Mobility',
    psychological_behavioural: 'Psychological / Behavioural',
    skin_integrity: 'Skin Integrity / Wound Management',
    speech_swallowing: 'Speech / Swallowing',
  },
  time_list: {
    morning: 'Morning',
    afternoon: 'Afternoon',
    evening: 'Evening',
    overnight: 'Overnight',
  },
  estimated_hour_list: {
    less_than_five: 'Less than 5',
    five: '5-10',
    ten: '10-20',
    twenty: 'More than 20',
  },
  gender_preference: {
    female: 'Female Only',
    male: 'Male only',
    no_preference: 'No Preferences',
  },
  media_to_know: {
    facebook: 'Facebook',
    instagram: 'Instagram',
    google_search: 'Google Search',
    newspaper: 'Newspaper',
    refer_by_friends: 'Refer by Friends',
    others: 'Others',
  },
  working_hour_support: {
    one_to_ten: '1-10 hours',
    eleven_to_twenty_five: '11-25 hours',
    twenty_six_to_thirty_five: '26-35 hours',
    more_than_thirty_six: 'More than 36 hours',
  },
}

export default en
