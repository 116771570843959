import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import i18n from '../common/plugins/vue-i18n'
import TokenMixin from '@/mixins/TokenMixin'

Vue.config.productionTip = false

Vue.mixin(TokenMixin)

Vue.filter('format_date', function (value) {
  // to YYYY-MM-DD format
  if (!value) return ''
  return value.split('T')[0]
})

Vue.filter('format_dateTime', function (value) {
  // to YYYY-MM-DD hh:mm:ss format
  if (!value) return ''
  let date = value.substring(0, 10)
  let time = value.substring(11, 16)
  return date + ' ' + time
})

Vue.filter('format_job_type', function (value) {
  if (!value) return ''

  let result = ''

  switch (value) {
    case 'last_minute':
      result = i18n.t('job_type.last_minute')
      break
    case 'standard':
      result = i18n.t('job_type.standard')
      break
    case 'private':
      result = i18n.t('job_type.private')
      break
  }

  return result
})

Vue.filter('format_approval_status', function (value) {
  let result = ''

  if (value) {
    result = i18n.t('status.approved')
  } else {
    result = i18n.t('status.pending')
  }

  return result
})

Vue.filter('format_payment_method', function (value) {
  if (!value) return ''

  let result = ''

  switch (value) {
    case 'offline':
      result = i18n.t('job_post.offline')
      break
    case 'credit_card':
      result = i18n.t('job_post.credit_card')
      break
  }

  return result
})

Vue.filter('format_status', function (value) {
  if (!value) return ''

  let result = ''

  switch (value) {
    case 'active':
      result = i18n.t('status.active')
      break
    case 'inactive':
      result = i18n.t('status.inactive')
      break
    case 'pending':
      result = i18n.t('status.pending')
      break
    case 'approved':
      result = i18n.t('status.approved')
      break
    case 'completed':
      result = i18n.t('status.completed')
      break
    case 'accepted':
      result = i18n.t('status.accepted')
      break
    case 'declined':
      result = i18n.t('status.decline')
      break
    case 'paid':
      result = i18n.t('status.paid')
      break
    case 'filled':
      result = i18n.t('status.filled')
      break
    case 'canceled':
      result = i18n.t('status.canceled')
      break
  }

  return result
})


Vue.filter('format_timesheet_status', function (value) {
  if (!value) return ''

  let result = ''

  switch (value) {
    case 'pending':
      result = i18n.t('status.pending')
      break
    case 'approved':
      result = i18n.t('status.completed') // NOTICE
      break
    case 'unapproved':
      result = i18n.t('status.decline') // NOTICE
      break
  }

  return result
})

Vue.prototype.$func = new (function () {
  this.log = value => {
    const currentLocation = window.location.hostname;
    if (currentLocation.includes("localhost") || currentLocation.includes("innpressionhost")) {
      console.log(value)
    }
  }
})()


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
