import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../config/i18n/en'

Vue.use(VueI18n)

let messages = {}
messages = { ...messages, en }

const lang = localStorage.getItem('language') || 'en'

const i18n = new VueI18n({
  locale: lang,
  messages,
})
export default i18n
