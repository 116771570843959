<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'TokenMixin',
  methods: {
    getToken() {
      return Cookies.get('CARECONNECT_CMS_LID')
    },
    async checkToken() {
      let resultText = 'Login Expired. Please login again'
      let resultType = false

      const token = this.getToken()
      if (token !== undefined && token !== null && token !== '') {
        try {
          const params = new URLSearchParams()
          params.set('admin_token', token)
          const response = await axios.post('/backend/develop/request.php', params)

          if (response.data.result === 'success') {
            if (response.data.data === token) {
              resultType = true
            }
          }
        } catch (error) {
          this.$func.log('---token fail---')
          this.$func.log(error)
        }
      }

      if (!resultType) {
        Cookies.remove('CARECONNECT_CMS_LID')
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: resultText,
          type: 'error',
          refresh: false,
          redirect: 'Login',
        })
        this.$store.dispatch('toggleOverlay', true)
      }

      return resultText
    },
  },
}
</script>
