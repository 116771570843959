<template>
  <!-- https://ithelp.ithome.com.tw/articles/10223518 -->
  <!-- $emit used when child element want to pass some data to parent element-->
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link :to="{ name: 'Worker' }" class="d-flex align-center text-decoration-none">
        <v-slide-x-transition>
          <h2 class="app-title text--primary">{{ $t('general.name') }}</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5" v-for="link in nav_link" :key="link.title">
      <nav-menu-link v-if="!link.isGroup" :title="link.title" :to="link.name" :id="link.id"></nav-menu-link>
      <nav-menu-group v-else :title="link.title">
        <nav-menu-link
          v-for="sub_group in link.subGroup"
          :key="sub_group.title"
          :title="sub_group.title"
          :to="sub_group.name"
        ></nav-menu-link>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    nav_link: [
      {
        isGroup: false,
        title: 'navigation.worker',
        name: { name: 'Worker' },
      },
      {
        isGroup: false,
        title: 'navigation.customer',
        name: { name: 'Customer' },
      },
      {
        isGroup: false,
        title: 'navigation.job_post',
        name: { name: 'JobPost' },
      },
      {
        isGroup: false,
        title: 'navigation.news',
        name: { name: 'News' },
      },
      {
        isGroup: false,
        title: 'navigation.enquiry',
        name: { name: 'Enquiry' },
      },
      {
        isGroup: true,
        title: 'navigation.report',
        subGroup: [
          { title: 'navigation.new_bug_report', name: { name: 'NewBugReport' } },
          { title: 'navigation.report_user', name: { name: 'UserReport' } },
          { title: 'navigation.report_accident', name: { name: 'AccidentReport' } },
          { title: 'navigation.job_report', name: { name: 'JobReport' } },
        ],
      },
      {
        isGroup: false,
        title: 'navigation.payment_record',
        name: { name: 'PaymentRecord' },
      },
      {
        isGroup: false,
        title: 'navigation.payroll',
        name: { name: 'Payroll' },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
