import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      skipAuth: true,
    },
  },
  {
    name: 'Worker',
    path: '/worker',
    component: () => import('@/views/pages/Worker.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'WorkerDetail',
    path: '/worker/detail/:id',
    component: () => import('@/views/pages/WorkerDetail.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'Customer',
    path: '/customer',
    component: () => import('@/views/pages/Customer.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'CustomerDetail',
    path: '/customer/detail/:id',
    component: () => import('@/views/pages/CustomerDetail.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'JobPost',
    path: '/job-post',
    component: () => import('@/views/pages/JobPost.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'JobPostDetail',
    path: '/job-post/detail/:id',
    component: () => import('@/views/pages/JobPostDetail.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'News',
    path: '/news',
    component: () => import('@/views/pages/News.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'addNews',
    path: '/news/detail',
    component: () => import('@/views/pages/NewsDetail.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'NewsDetail',
    path: '/news/detail/:id',
    component: () => import('@/views/pages/NewsDetail.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'Enquiry',
    path: '/enquiry',
    component: () => import('@/views/pages/Enquiry.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'NewBugReport',
    path: '/bug-report',
    component: () => import('@/views/pages/NewBugReport.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'JobReport',
    path: '/job-report',
    component: () => import('@/views/pages/JobReport.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'UserReport',
    path: '/user-report',
    component: () => import('@/views/pages/UserReport.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'AccidentReport',
    path: '/accident-report',
    component: () => import('@/views/pages/AccidentReport.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'PaymentRecord',
    path: '/payment-record',
    component: () => import('@/views/pages/PaymentRecord.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'Payroll',
    path: '/payroll',
    component: () => import('@/views/pages/Payroll.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'PayrollDetail',
    path: '/payroll/detail/:id',
    component: () => import('@/views/pages/PayrollDetail.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // login cookie check
    const loginCookie = Cookies.get('CARECONNECT_CMS_LID')
    if (loginCookie !== undefined && loginCookie !== '') {
      next()
    } else if (to.path !== '/login') {
      next('/login')
    } else {
      next()
    }
  } else if (to.meta.skipAuth) {
    const loginCookie = Cookies.get('CARECONNECT_CMS_LID')
    if (loginCookie !== undefined && loginCookie !== '') {
      next('/worker')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
